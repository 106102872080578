import React from 'react'
import PropTypes from 'prop-types'

import LogoImage from '../components/logoImage'
import FacebookLogo from "../images/facebook-logo.svg"
import InstagramLogo from "../images/instagram-logo.svg"

const Layout = ({children}) => (
  <>
    <header>
      <div className="container">
        <nav className="navbar">
          <a href="/" className="navbar__logo" title="Accueil - Monsieur Denis">
            <LogoImage />
          </a>
          <div className="navbar__social">
            <a href="https://www.facebook.com/monsieurdenisapp" target="_blank" rel="noopener noreferrer" title="Suivez-nous sur Facebook">
              <img src={FacebookLogo} alt="Facebook Logo" width="20"/>
            </a>
            <a href="https://www.instagram.com/monsieurdenisapp/" target="_blank" rel="noopener noreferrer" title="Suivez-nous sur Instagram">
              <img src={InstagramLogo} alt="Instagram Logo" width="20"/>
            </a>
          </div>
        </nav>
      </div>
    </header>
    {children}
    <footer>Made with <span role="img" aria-label="heart">❤️</span> by <a href="https://www.linkedin.com/in/oanez-le-berre-a3b59baa/" rel="noopener noreferrer" target="_blank">Oanez</a> & <a href="https://linkedin.com/in/matthieu-fesselier-bb15b0aa/" rel="noopener noreferrer" target="_blank">Matthieu</a></footer>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


export default Layout
