import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import GooglePlayImage from '../components/googlePlayImage'

const NotFoundPage = () => (
  <Layout>
    <SEO />
    <div className="error container">
      <h2>404</h2>
      <h1>Petit problème&nbsp;...</h1>
      <p>Je n'ai rien trouvé ici ! Par contre tu peux toujours télécharger mon application : </p>
      <a href='https://play.google.com/store/apps/details?id=com.oama.mdenis&utm_source=landing&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' title="Installez l'application Monsieur Denis">
        <GooglePlayImage />
        </a>
    </div>
  </Layout>
)

export default NotFoundPage
