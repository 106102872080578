module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Monsieur Denis - Les savoirs utiles aux voyageurs', // Navigation and Site Title
  siteTitleAlt: 'Les savoirs utiles aux voyageurs', // Alternative Site title for SEO
  siteTitleShort: 'Les savoirs utiles aux voyageurs', // short_name for manifest
  siteHeadline: 'Monsieur Denis - Les savoirs utiles aux voyageurs', // Headline for schema.org JSONLD
  siteUrl: 'www.monsieur-denis.com', // Domain of your site. No trailing slash!
  siteLanguage: 'fr', // Language Tag on <html> element
  siteLogo: '/images/m-denis-screenshots.jpg', // Used for SEO and manifest
  siteDescription: 'Monsieur Denis est le compagnon indispensable des voyageurs. Chaque matin, vous recevez un savoir utile sur le pays que vous visitez, pour tout comprendre des comportements et des habitudes des populations que vous rencontrerez&nbsp;!',
  author: 'Matthieu Fesselier & Oanez Le Berre', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  //ogSiteName: '', // Facebook Site Name
  //ogLanguage: 'fr_FR', // Facebook Language

  // Manifest and Progress color
  themeColor: "#ffcc4c",
  backgroundColor: "#ffcc4c",
}